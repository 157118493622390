import type { FunctionComponent } from 'react';
import { default as IconCompleted} from '../../../assets/images/payment/Check.svg'
const Completed: FunctionComponent = () => (
  <div className='margin-100'>
    <div className='row'>
      <div className='col-12 text-center'>
        <img 
          src={IconCompleted} 
          height={100}
          width={100} 
        >
        </img>
      </div>
      <div className='col-12 text-center mt-4'>
        <p className='h5 text-completed'>Your order has been completed.</p>
        <p className='h5' >The confirmation will be sent to your email shortly.</p>
      </div>
    </div>
  </div>
);

export default Completed