import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, IProductList } from '../../store/productlist';
import { useTranslation } from 'react-i18next';

import type { FunctionComponent } from 'react';

import type { RootState } from '../../store';
import { ICategory  } from '../../store/categories/types';
import ProductGrid from './ProductGrid';
import { ISelectedItem } from '../../store/filter-product';

const ProductList: FunctionComponent = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const filterProduct = useSelector<RootState, {
        isLoading: boolean;
        selectedCategories: ISelectedItem[];
        selectedBrands: ISelectedItem[];
        minPrice?: number;
        maxPrice?: number;
    }>((state) => state.filterProduct);

    const products = useSelector<RootState, IProductList[]>((state) => {
        return state.productList.products!;
    });

    useEffect(() => {
        dispatch(actionCreators.requestProductList(filterProduct.selectedCategories, filterProduct.selectedBrands, filterProduct.minPrice, filterProduct.maxPrice, i18n.resolvedLanguage));
    }, [filterProduct, i18n]);

    return (
        <ProductGrid products={products} />
    );
};

export default ProductList;