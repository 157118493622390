import { SampleProductList, ProductDetail, Completed, Failed, Notfound, Home } from './../containers';

import type { ComponentType } from 'react';
import type { Params } from 'react-router';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';


export const TRANSITION_DEFAULT = {
  classNames: 'fade',
  timeout: { enter: 250, exit: 250 }
};

export type RouteComponent = ComponentType<any>;
export type TransitionMetaData = typeof TRANSITION_DEFAULT;
const language = `/:language`;
export type Route = Readonly<{
  name: string;
  path: string;

  icon?: IconProp;
  showInNav?: boolean;
  Component: RouteComponent;
  transition: TransitionMetaData;
  params?: Readonly<Params<string>>;
}>;

export const Routes: Route[] = [
  {
    path: '/',
    name: 'Home',
    Component: Home,
    transition: TRANSITION_DEFAULT,
  },
  {
    path: `${language}/`,
    name: 'Home',
    Component: Home,
    transition: TRANSITION_DEFAULT,
  },
  {
    showInNav: true,
    name: 'Products',
    path: '/products',
    Component: SampleProductList,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    },
    params: {
      startDateIndex: '0'
    }
  },
  {
    showInNav: true,
    name: 'Products',
    path: `${language}/products`,
    Component: SampleProductList,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    },
    params: {
      startDateIndex: '0'
    }
  },
  {
    showInNav: true,
    name: 'Product',
    path: `${language}/product/:id`,
    Component: ProductDetail,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
  {
    showInNav: true,
    name: 'Product',
    path: '/product/:id',
    Component: ProductDetail,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
  {
    showInNav: true,
    name: 'PaymentCompleted',
    path: `${language}/payment/completed`,
    Component: Completed,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
  {
    showInNav: true,
    name: 'PaymentCompleted',
    path: `/payment/completed`,
    Component: Completed,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
  {
    showInNav: true,
    name: 'PaymentFailed',
    path: `${language}/payment/failed`,
    Component: Failed,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
  {
    showInNav: true,
    name: 'PaymentFailed',
    path: `/payment/failed`,
    Component: Failed,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
  {
    showInNav: true,
    name: 'Notfound',
    path: `${language}/notfound`,
    Component: Notfound,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
  {
    showInNav: true,
    name: 'Notfound',
    path: `/notfound`,
    Component: Notfound,
    transition: {
      classNames: 'page-slide-right',
      timeout: { enter: 350, exit: 250 }
    }
  },
];
