import React from "react";
import { useTranslation } from "react-i18next";
const NotFoundContainer: React.FunctionComponent = () => {


const { t } = useTranslation();

return (
  <div className='margin-100 container'>
    <div className='row'>
      <div className='col-12 text-center'>
        <p className='h3'>{t("notfound.header")}</p>
      </div>
      <div className='col-12 text-center mt-4'>
        <p className='h5'>{t("notfound.title")}</p>
        <p className='h5' >{t("notfound.description")}</p>
      </div>
    </div>
  </div>

);
};
export default NotFoundContainer;
