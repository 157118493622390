/* eslint-disable @typescript-eslint/no-redeclare */

import { type } from "os";

export const ProductActionType = {
    GET_PRODUCT: 'filtercategory/resetState',
  } as const;
  
export type IProductImage = Readonly<{
    id: string,
    src: string,
    altText: string,
}>;
export type IProductDetail = Readonly<{
    id: string,
    brand: string,
    name: string,
    description: string,
    salePrice: number,
    price: number,
    isAvailable: boolean,
    stocks: number,
    shippingPrice: number,
    productId: string,
    variantId: string,
    channelId: string,
}>;  
export type IProductInformation = Readonly<{
    detail: string,
    specification: string,
    warning: string,
}>; 
export type IProduct = Readonly<{
    images: IProductImage[];
    detail: IProductDetail;
    information: IProductInformation;
}>;
export type IProductState = Readonly<{
    product: IProduct | null
}>;
  
export type ProductPayload = Partial<IProductState>;
  
  