import React from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
  } from "reactstrap";
import { IProductImage } from '../../store/product';

interface ImageSliderProps{
    imageItems: IProductImage[],
    discountPercent?: number,
    maxItems?: number
}
const ImageSlider: React.FunctionComponent<ImageSliderProps> = ({imageItems, discountPercent = 0, maxItems = 0, }: ImageSliderProps) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const items = maxItems <= 0 ? imageItems : imageItems.slice(0, maxItems)
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      };
      const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
      };
      const slides = items.map(item => {
        return (
          <CarouselItem
            tag="div"
            key={item.id}
            onExiting={() => setAnimating(false)}
            onExited={() => setAnimating(false)}
          >
            <div className='swiper-slide-cover'></div>
            <img className="d-block w-100" src={item.src} alt={item.altText} />
          </CarouselItem>
        );
      });
      return (
        <div className='content product_detail'>
            { discountPercent > 0 && discountPercent <= 100 ? <span className='ribbon discount detail' >OFF<i>-{discountPercent}%</i></span> : null }
            <Carousel 
                activeIndex={activeIndex} 
                next={next} 
                previous={previous}
                interval={false}
            >
                {slides}
                <CarouselControl
                    className='swiper-prev'
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    className='swiper-next'
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
            <div className="gallery-thumbs">
                <div className="swiper-wrapper">
                {items.map((item, index) => {
                    return (
                      <div className='swiper-slide' key={item.id}>
                        <img 
                            className={ activeIndex === index ? 'swiper-slide-thumb-active': '' }
                            src={item.src} 
                            alt={item.altText}
                            onClick={() => goToIndex(index)}
                        />
                      </div>
                    );
                })}
                </div>
            </div>
        </div>
      );
}
export default ImageSlider;
  