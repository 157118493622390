/* eslint-disable @typescript-eslint/no-redeclare */

export const FilterProductActionType = {
  RESET_STATE: 'filtercategory/resetState',
  CATEGORY_ADD: 'filtercategory/category/add',
  CATEGORY_DELETE: 'filtercategory/category/delete',
  BRAND_ADD: 'filtercategory/brand/add',
  BRAND_DELETE: 'filtercategory/brand/delete',
  MINPRICE_SET: 'filtercategory/minprice/set',
  MAXPRICE_SET: 'filtercategory/maxprice/set',
} as const;

export type ISelectedItem = Readonly<{
  id: string;
  key?: string;
  parent: {id:string,typeId:string};
  name: string;
}>;

export type IFilterProductState = Readonly<{
  isLoading: boolean;
  selectedCategories: ISelectedItem[];
  selectedBrands: ISelectedItem[];
  minPrice?: number | undefined;
  maxPrice?: number | undefined;

}>;

export type FilterProductPayload = Partial<IFilterProductState>;

