import * as React from "react";
import { memo } from "react";
import { Cart } from "@bumrungrad/b8d-commerce";
import type { IProductListState } from "../../store/productlist";

import { useTranslation, getI18n } from "react-i18next";
import { actionCreators, ICart } from "../../store/cart";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getMoney } from "../../utils/common";

type ProductGridProps = Pick<IProductListState, "products">;

const handleClickLink = (codeName?: string) => {
  return `/${getI18n().language}/product/${codeName}`;
};

const ProductGrid = memo<ProductGridProps>(({ products }) => {
  const dispatch = useDispatch();
  return (
    <div className="rows infinite_row product-feed">
      {products?.map(
        ({
          skuCode,
          productName,
          price,
          discountPrice,
          discountPercent,
          productThumbnail,
          description,
          productKey,
          channelId,
          codeName,
          productId,
          variantId,
        }) => (
          <div className="thumb feed cols-lg-3 cols-md-4 cols-xs-6" key={skuCode}>
            <div className="thumb_content_holder">
              <Link
                className="thumb_content_holder_over"
                to={handleClickLink(codeName)}
              >
                {(discountPercent ? 0 : 0 > 0) && (
                  <span className="ribbon discount">
                    OFF<i>-{discountPercent}%</i>
                  </span>
                )}
                <img
                  className="img_size"
                  src={
                    productThumbnail == ""
                      ? "./assets/images/defualt.png"
                      : productThumbnail
                  }
                />
                <div className="thumb_content">
                  {productName}
                  <b>
                    ฿ {getMoney(price??0)}
                    {(discountPrice ? 0 : 0 > 0) && (
                      <span className="discount">
                        &nbsp;฿ {getMoney(discountPrice??0)}&nbsp;
                      </span>
                    )}
                  </b>
                </div>
              </Link>
              <span className="buy_button">
                <Cart
                  channelId={channelId!}
                  qty={1}
                  productId={productId!}
                  variantId={variantId!}
                  type="BuyNow_l"
                  text="&nbsp;&nbsp;&nbsp; BUY NOW"
                  callBack={(cart) => {
                    dispatch(actionCreators.setCart(cart as ICart));
                  }}
                />
                <Cart
                  channelId={channelId!}
                  qty={1}
                  productId={productId!}
                  variantId={variantId!}
                  type="AddToCart_l"
                  callBack={(cart) => {
                    dispatch(actionCreators.setCart(cart as ICart));
                  }}
                />
              </span>
            </div>
          </div>
        )
      )}
    </div>
  );
});

ProductGrid.displayName = "Products";

export default ProductGrid;
