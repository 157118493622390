import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router';
import { useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import { hot } from 'react-hot-loader/root';
import { Routes as routes, TRANSITION_DEFAULT } from './config';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import './custom.css'


const App: FunctionComponent = () => {


    const location = useLocation();
    const cssKey = location.pathname?.split('/')[1] || '/';
    const curRoute = routes.find((x) => (x.path[0] === cssKey) || (x.name.toLowerCase() === cssKey.toLowerCase()));
    const { timeout, classNames } = curRoute?.transition ?? TRANSITION_DEFAULT;
    return (
        <Layout>
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={cssKey}
                    timeout={timeout}
                    classNames={classNames}
                >
                    <Routes location={location}>
                        {routes.map(({ path, params, Component }) => (
                            <Route
                                key={path}
                                path={path}
                                element={<Component />}>
                                {
                                    params && Object.getOwnPropertyNames(params).map((value) => (
                                        <Route key={''} path={':' + value} element={<Component />} />
                                    ))
                                }

                            </Route>

                        ))}
                    </Routes>
                </CSSTransition>
            </SwitchTransition>
        </Layout>
    );
};

export default hot(App);