
import { CartActionType, ICart } from './types';
import type { AppThunk } from '..';
import type { CartPayload } from './types';
export const actionCreators = {
  setCart: (cart: ICart): AppThunk<CartPayload> => async (dispatch, getState) => {
      try {
        dispatch({
          type: CartActionType.SET_CART,
          payload: { 
            totalItems: cart.totalItems,
            version: cart.version,
          },
        });
      } catch (e) {
        
      };
  },
};
