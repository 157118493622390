import { Fragment, FunctionComponent, useEffect, useRef } from 'react';
import React from 'react';
import {isMobile} from 'react-device-detect';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionHeader, AccordionItem, Button, CardBody, Collapse, Input, NavItem, UncontrolledCollapse } from 'reactstrap';


import { RootState } from './../../store';
import { ICategory } from './../../store/categories/types';
import { actionCreators, ISelectedItem } from './../../store/filter-product';
import { IconicSpinner } from '../../components';
import { useTranslation } from 'react-i18next';

const ProductFilter: React.FunctionComponent = () => {
    const inputMinRef = React.createRef<HTMLInputElement>();
    const inputMaxRef = React.createRef<HTMLInputElement>();
    const productCategories = useSelector<RootState, ICategory[]>((state) => state.categories.productCategories);
    const brandCategories = useSelector<RootState, ICategory[]>((state) => state.categories.brandCategories);
    const productIsLoading = useSelector<RootState, boolean>((state) => state.categories.productIsLoading);
    const brandIsLoading = useSelector<RootState, boolean>((state) => state.categories.brandIsLoading);
    const filterProduct = useSelector<RootState,{ isLoading: boolean;
        selectedCategories: ISelectedItem[];
        selectedBrands: ISelectedItem[];
        minPrice?: number;
        maxPrice?: number;
    } >((state) => state.filterProduct);
    
    const dispatch = useDispatch();
   
    const limitShowBrand = 2;
    const [isOpenFadder, setIsOpenFadder] = React.useState(false);
    const [isOpenFilter, setIsOpenFilter] = React.useState(false);

    const [isSeeAll, setIsSeeAll] = React.useState(false);
    const [isShowBrand, setIsShowBrand] = React.useState(true);
    const [isShowCategory, setIsShowCategory] = React.useState(true);
    const [min, setMin] = React.useState(1);
    const [max, setMax] = React.useState(0);


    const toggleFilter = () => {

        setIsOpenFilter(!isOpenFilter);
        toggleFadder(!isOpenFadder);
        if (!isOpenFilter) {
            document.body.classList.add("hide-scoll");
        } else {
            document.body.classList.remove("hide-scoll");
        }
    }
    const toggleFadder = (value : Boolean) => {

        setIsOpenFadder(!!value);
        
    }
    const toggleSeeAll = () => {
        setIsSeeAll(!isSeeAll);
    }
    const toggleBrand = () => {
        setIsShowBrand(!isShowBrand);
    }
    const toggleCategory = () => {
        setIsShowCategory(!isShowCategory);
    }
    const closeFilter = () => {
        setIsOpenFilter(false);
        toggleFadder(false);
    }

    const selectedFilterHandlerClick = (item: {id:string, key?:string, parent: {id:string,typeId:string}, name: string}) => {

        dispatch(actionCreators.addCategory(item));
        
    }

    const handlerPriceChange = () => {
        if(inputMinRef.current?.value === undefined || !inputMinRef.current?.value){
            dispatch(actionCreators.setMinPrice(undefined));
        }else{
            dispatch(actionCreators.setMinPrice(parseFloat(inputMinRef.current?.value)));
        }

        if(inputMaxRef.current?.value === undefined || !inputMaxRef.current?.value || inputMaxRef.current?.value?.trim() ===''|| inputMaxRef.current?.value?.trim() ==='0'){
            
            dispatch(actionCreators.setMaxPrice(undefined));
        }else{
            dispatch(actionCreators.setMaxPrice(parseFloat(inputMaxRef.current?.value)));
        }
        closeFilter();
    }


    const handlerPriceReset = () => {
        setMin(1);
        setMax(-1);
        dispatch(actionCreators.setMinPrice(undefined));
        dispatch(actionCreators.setMaxPrice(undefined));
        dispatch(actionCreators.clearAll());
        closeFilter();
    }

    const handlerPriceRangeClick = () => {

        dispatch(actionCreators.setMinPrice(undefined));
        dispatch(actionCreators.setMaxPrice(undefined));

    }
    const categoryHandlerClick = async (item: {id:string, parent: {id:string,typeId:string}, name: string}) => {
        dispatch(await actionCreators.addCategory(item));
    }

    const brandHandlerClick = async (event: React.FormEvent<HTMLInputElement>, item: {id:string, key:string, parent: {id:string,typeId:string}, name: string}) => {
        if(event.currentTarget?.checked){
              dispatch(await actionCreators.addBrand(item));
        }else{
              dispatch(await actionCreators.deleteBrand(item));
        }
    }

    const selectedFilterBrandHandlerClick = (item: {id:string, key?:string, parent: {id:string,typeId:string}, name: string}) => {

        dispatch(actionCreators.deleteBrand(item));
        
      } 
    const CheckBoxComponent  = (item: {id:string, parent: {id:string,typeId:string}, name: string,checked: boolean, isParent:boolean})=> {
        return (
            <label className="product list product_c" id={item.id} onChange={(e)=>categoryHandlerClick(item)} >
                <input id={item.id}  type="checkbox" checked={item.checked} name="category" onChange={(e)=>function(){}} value={item.id} />
                {item.isParent && <i></i>}
                <div>{item.name}</div>
                <span className="checkmark"></span>
               
            </label>
        )
    
        
    }

    const { t } = useTranslation();
    
    return (
        <React.Fragment>
            <div className={"order-last order-lg-first side"+(isOpenFilter?" show":"")}>
                <div className={"filter_close show_mobile"} onClick={toggleFilter}></div>
                <div className={"filter_by show_mobile"}>
                {(filterProduct.selectedCategories?.length>0 || filterProduct.selectedBrands?.length>0 || (!!filterProduct.minPrice || !!filterProduct.maxPrice)) && <b>Filtered By:</b>}
                    
                    {
                        filterProduct.selectedCategories && filterProduct.selectedCategories.map((item)=> (
                            <span key={item.id} className='selected-filter' onClick={()=>selectedFilterHandlerClick(item)}>{item.name}</span>
                        ))
                        
                    }    
                    {
                        filterProduct.selectedBrands && filterProduct.selectedBrands.map((item)=> (
                            <span key={item.id} className='selected-filter'  onClick={()=>selectedFilterBrandHandlerClick(item)}>{item.name}</span>
                        ))
                    }    
                    {(!!filterProduct.minPrice || !!filterProduct.maxPrice) && <span className='selected-filter' onClick={()=>handlerPriceRangeClick()} > Price Range : {filterProduct.minPrice?Intl.NumberFormat().format(filterProduct.minPrice):'1'} - {filterProduct.maxPrice?Intl.NumberFormat().format(filterProduct.maxPrice):'Max'}</span>}
                </div>
                <div className="product menu">
                    <h2 id="sidetab_product" className={isShowCategory ? "" : "show collapse"} onClick={toggleCategory}>{t('product_filter.our_products')}</h2>
                    <Collapse isOpen={isShowCategory} >
                        <IconicSpinner isLoading={productIsLoading}/>
                        {
                            !!!productIsLoading && productCategories && productCategories?.map((item) => (
                                <Fragment key={item.id}>
                                    <CheckBoxComponent {...{...item,checked:!!filterProduct.selectedCategories.find(x=>x.id==item.id),isParent:item.child && item.child.length>0}}/>
                                    {
                                        item.child && item.child.length>0 && 
                                        <Collapse  isOpen={!!filterProduct.selectedCategories.find(x=>x.id==item.id)} className='sub-category'>
                                            {
                                                item.child && item.child.length>0 && item.child?.map((child) => (
                                                    <Fragment key={child.id}>
                                                        <CheckBoxComponent {...{...child,checked:!!filterProduct.selectedCategories.find(x=>x.id==child.id),isParent:false}}/>
                                                        
                                                    </Fragment>
                                                    
                                                ))
                                            }
                  
                                        </Collapse >
                                    }
                                </Fragment>
                                
                            ))
                        }

                    </Collapse>
                </div>
                <div className="brand menu">
                    <h2 id="sidetab_brand" className={isShowBrand ? "mt-3" : "mt-3 show collapse"} onClick={toggleBrand}>{t('product_filter.brand')}</h2>
                    <Collapse isOpen={isShowBrand}>
                        <IconicSpinner isLoading={brandIsLoading}/>
                        {
                            !!!brandIsLoading && brandCategories && brandCategories?.slice(0, limitShowBrand).map((item) => (
                                <label className="product list product_c" key={item.id} >{item.name}<input type="checkbox" name="brand" value={item.id} checked={!!filterProduct.selectedBrands.find(x=>x.id==item.id)} onChange={(e)=>brandHandlerClick(e, item)}/><span className="checkmark"></span></label>
                            ))
                        }
                        {brandCategories && brandCategories.length > limitShowBrand &&
                        <React.Fragment>
                            <Collapse  isOpen={isSeeAll}>
                                
                                {
                                    brandCategories && brandCategories?.slice(limitShowBrand, brandCategories.length).map((item) => (
                                        <label className="product list product_c" key={item.id} >{item.name}<input type="checkbox" name="brand" value={item.id} checked={!!filterProduct.selectedBrands.find(x=>x.id==item.id)}  onChange={(e)=>brandHandlerClick(e, item)}/><span className="checkmark"></span></label>
                                    ))
                                }
                                </Collapse>
                                <label onClick={toggleSeeAll} className='my-2 sidetab_brand_see_all'>{isSeeAll ? 'Show less' : 'Show All'}</label>
                        </React.Fragment>
                        
                        }
                    </Collapse>
                </div>
                <div className="price menu">
                    <h2 id="sidetab_price" className='mt-3'>{t('product_filter.price')}</h2>
                <div className="price_c">
                    <input type="number" id="price_min" name="price_min" min="1" max="999999999" value={(min<=0 || isNaN(min))?'1':min} onChange={(e)=>setMin(parseFloat(e.target.value))} ref={inputMinRef}/> - <input type="number" id="price_max" name="price_max"  min="1"  max="999999999" placeholder="Max"   value={max<=0 || isNaN(max)?'':max} onChange={(e)=>setMax(parseFloat(e.target.value))}  ref={inputMaxRef}/>
                </div>
                </div>
                <div className="filter_button">
                <span className="bt reset"  onClick={handlerPriceReset}>RESET</span>
                <span className="bt submit"  onClick={handlerPriceChange}>SUBMIT</span>
                </div>
                
            </div>
            <div className="filter_fade" style={isOpenFadder?{'display': 'block'}:{'display': 'none'}}></div>
            <div className="filter_btn show_mobile" onClick={toggleFilter}>Filters ({filterProduct.selectedCategories.length + filterProduct.selectedBrands.length + ((!!filterProduct.minPrice || !!filterProduct.maxPrice)?1:0)})</div>
        </React.Fragment>
    );
}

export default (ProductFilter);

