import * as React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import IconFbChat from "./../assets/images/ic_fbchat.png";
import IconGotoTop from "./../assets/images/ic_dropdown_g_over.png";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { CartIcon } from "@bumrungrad/b8d-commerce";
import { RootState } from "../store";
import { useDispatch, useSelector, useStore } from "react-redux";
import { ICart } from "../store/cart";
import { actionCreators } from "../store/cart";
const NavMenu: React.FunctionComponent = () => {
  const [isOpenList, setIsOpenList] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState<number | undefined>(
    undefined
  );
  const [version, setVersion] = React.useState<number | undefined>(undefined);
  const [isOpenFadder, setIsOpenFadder] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState("");
  const [gotoTopVisible, setGotoTopVisible] = React.useState(false);
  const [isShowLanguage, setIsShowLanguage] = React.useState(false);
  const divMenuListRef = React.createRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const cartState = useSelector<
    RootState,
    {
      totalItems?: number;
      version?: number;
    }
  >((state) => state.cart);

  const mouseOverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isMobile) {
      return;
    }
    removeActive();
    const box: HTMLDivElement = event.currentTarget;
    const subMenu: HTMLDivElement = Array.from(box.children).filter((chapter) =>
      chapter.classList.contains("menu_sub")
    )[0] as HTMLDivElement;
    if (subMenu && subMenu.style) {
      subMenu.style.display = "block";
    }
    setSelectedMenu(event.currentTarget.dataset.value ?? "");
  };

  const mouseOutHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    removeActive();
  };

  const removeActive = () => {
    if (divMenuListRef.current?.children) {
      let mainMenuList = Array.from(divMenuListRef.current?.children);
      mainMenuList.map((item) => {
        const a = Array.from(item.children).filter((chapter) =>
          chapter.classList.contains("menu_sub")
        )[0] as HTMLDivElement;
        if (a) {
          a.style.display = "none";
        }
      });
    }
  };

  const mouseClickMainMenu = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setSelectedMenu(event.currentTarget.dataset.value ?? "");
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setGotoTopVisible(true);
    } else if (scrolled <= 300) {
      setGotoTopVisible(false);
    }
  };

  const toggleLanguage = () => {
    setIsShowLanguage(!isShowLanguage);
    toggleFadder(!isOpenFadder);
  };

  const toggleFadder = (value: Boolean) => {
    if (!value) {
      setIsOpenList(false);
      setIsShowLanguage(false);
    }
    setIsOpenFadder(!!value);
  };

  const mouseOutLanguageHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    toggleFadder(!isOpenFadder);
  };

  const mouseClickHamberger = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isOpenList) {
      setSelectedMenu("");
    }
    setIsOpenList(!isOpenList);
    toggleFadder(!isOpenList);
  };
  const gotoTop = () => {
    window.scrollTo(0, 0);
  };
  window.addEventListener("scroll", toggleVisible);

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const languages = ["en", "th"];
  const languageChangeHandler = (lang: string) => {
    toggleFadder(true);
    setIsShowLanguage(false);
    const pathName = location.pathname;
    const splitPath = pathName.split("/");

    let newPath = "";
    if (splitPath.length > 1) {
      let skip = 0;
      if (languages.includes(splitPath[1])) skip = 1;
      for (let i = 1 + skip; i < splitPath.length; i++) {
        newPath += "/" + splitPath[i];
      }
      newPath = `/${lang}${newPath}`;
    }

    i18n.changeLanguage(lang);
    if (newPath) navigate(`${newPath}`);
  };
  const FacebookMessenger = ()=>{
    window.open('https://m.me/iconicpharmacy/', '_blank')
  }
  React.useEffect(() => {
    if (cartState.totalItems !== totalItems) {
      setTotalItems(cartState.totalItems);
      setVersion(cartState.version);
    }
  }, [cartState]);
  return (
    <React.Fragment>
      <nav id="iconic_topbar">
        <div className="gototop">
          <div
            className="goup"
            style={gotoTopVisible ? { display: "block" } : { display: "none" }}
            onClick={gotoTop}
          >
            <img src={IconGotoTop} />
          </div>
          <div className="chat" onClick={FacebookMessenger}>
            <img src={IconFbChat} />
          </div>
        </div>
        <div className="popup">
          <div className="popup_fader"></div>
          <div id="popup_container"></div>
        </div>
        <div
          className="menu_fader"
          style={{ display: isOpenFadder ? "block" : "none" }}
          onClick={(e) => toggleFadder(false)}
        ></div>
        <div className="menu_bg"></div>
        <div
          className={"menu_ic_close" + (isOpenList ? " show" : "")}
          onClick={mouseClickHamberger}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div
          className={"menu_list" + (isOpenList ? " show" : "")}
          ref={divMenuListRef}
        >
          <div
            className="menu_main_container"
            data-value="m1"
            onMouseOver={mouseOverHandler}
          >
            <a
              className={
                "m1 menu_main" + (selectedMenu == "m1" ? " selected" : "")
              }
              data-value="m1"
              onClick={mouseClickMainMenu}
            >
              HEALTH &amp; ADVICE<i></i>
            </a>
            {selectedMenu == "m1" && (
              <div
                className="menu_sub"
                style={{ display: "block" }}
                onMouseOut={mouseOutHandler}
              >
                <span>
                  <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=hot-issues`}>Hot Issues</a>
                  <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=health`}>Health Articles</a>
                  <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=weight-control-exercise`}>
                    Weight Control &amp; Exercise
                  </a>
                  <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=birth-control`}>
                    Birth Control
                  </a>
                  <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=beauty-health-skincare`}>
                    Beauty Health
                    <br className="show_pc" />
                    And Skincare
                  </a>
                  <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=vitamin`}>
                    Vitamins &amp; Supplements
                    <br />
                    For Each Age
                  </a>
                </span>
              </div>
            )}
          </div>
          <div
            className="menu_main_container"
            onMouseOver={mouseOverHandler}
          >
            <Link to={`/${i18n.resolvedLanguage}/products`}
              className={
                "m2 menu_main" + (selectedMenu == "m2" ? " selected" : "")
              }
              data-value="m2"
              onClick={mouseClickMainMenu}
            >
              OUR PRODUCTS
            </Link >
            
          </div>
          <div
            className="menu_main_container"
            onMouseOver={mouseOverHandler}
            data-value="m3"
          >
            <a
              className={
                "m3 menu_main" + (selectedMenu == "m3" ? " selected" : "")
              }
              data-value="m3"
              onClick={mouseClickMainMenu}
              href="https://lin.ee/5LB0yGm"
            >
              PHARMACY SERVICE
            </a>
          </div>
          <div
            className="menu_main_container"
            onMouseOver={mouseOverHandler}
            data-value="m4"
          >
            <a
              className={
                "m4 menu_main" + (selectedMenu == "m4" ? " selected" : "")
              }
              data-value="m4"
              onClick={mouseClickMainMenu}
              href="https://lin.ee/5LB0yGm"
            >
              MEMBER SERVICE
            </a>
            
          </div>
          <div className="menu_main_container" onMouseOver={mouseOverHandler}>
            <a
              className="m5 menu_main"
              href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/contactus.html`}
              data-value="m5"
              onClick={mouseClickMainMenu}
            >
              CONTACT US
            </a>
          </div>
        </div>
        <div className="menu_search d-none">
          <div className="menu_search_box">
            <input placeholder="Search on Iconic Pharmacy" />
            <b></b>
          </div>
        </div>
        <div className="menu_super">
          <div className="menu_language show" onClick={toggleLanguage}>
            {i18n.resolvedLanguage.toUpperCase()}
            <span></span>
          </div>
          <CartIcon
            cssClass={`menu_cart_super ${totalItems}`}
            totalItems={totalItems}
            version={version}
          />
          <div
            className="menu_language_expand"
            style={isShowLanguage ? { display: "block" } : { display: "none" }}
            onMouseLeave={mouseOutLanguageHandler}
          >
            <span
              className="lang_EN"
              onClick={() => languageChangeHandler("en")}
            >
              EN - English
            </span>
            <span
              className="lang_TH"
              onClick={() => languageChangeHandler("th")}
            >
              TH - ไทย
            </span>
          </div>
          <a href={process.env.REACT_APP_API_LINE_ADDFRIEND}><div className="menu_account_super"></div></a>
        </div>
        <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}`}>
          <div className="menu_logo"></div>
        </a>
        <div className="menu_account_sub">
          <div className="menu_ic_close"></div>
          <h2>ACCOUNT SETTING</h2>
          <span>
            <Link to="/en/account.html">My Account</Link>
          </span>
          <span>
            <Link to="/en/account_address.html">My Address</Link>
          </span>
          <span>
            <Link to="/en/account_password.html">Change Password</Link>
          </span>
          <span>
            <Link to="#">Bank Accounts/Cards</Link>
          </span>
          <span>
            <Link to="#">My Purchases</Link>
          </span>
          <span>
            <Link to="#">My Product Reviews</Link>
          </span>
          <span>
            <Link to="#">My Wishlist</Link>
          </span>
          <span>
            <Link to="#">Logout</Link>
          </span>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavMenu;
