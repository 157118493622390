import { combineReducers } from 'redux';
import { reducer as WeatherForecastsReducer } from './weather-forecasts';
import { reducer as FilterProductReducer } from './filter-product';
import { reducer as CategoryReducer } from './categories';
import { reducer as ProductReducer } from './product';
import { reducer as ProductListReducer } from './productlist';
import { reducer as CartReducer } from './cart';
import type { RootState } from './index';

const rootReducer = () =>
  combineReducers<RootState>({
    categories: CategoryReducer,
    filterProduct: FilterProductReducer,
    weatherForecasts: WeatherForecastsReducer,
    product: ProductReducer,
    productList: ProductListReducer,
    cart: CartReducer

  });

export default rootReducer;