import { ProductListActionType } from './types';

import type { ReduxAction, AppThunk } from '../';
import type { ProductListPayload } from './types';
import { ICategory } from '../categories/types';
import { ProductApi } from '../../api/product.service';
import { ISelectedItem } from '../filter-product';

export const actionCreators = {
    resetState: (): ReduxAction<ProductListPayload> => ({
        type: ProductListActionType.RESET_STATE,
    }),
    requestProductList: (
        categoreis?: ISelectedItem[],
        brands?: ISelectedItem[],
        priceMin?: number,
        priceMax?: number,
        language?: string
    ): AppThunk<ProductListPayload> =>
            async (dispatch, getState) => {
                // Dispatch request to intialize loading phase
                dispatch({
                    payload: { },
                    type: ProductListActionType.REQUEST
                });

                // Build http request and success handler in Promise<void> wrapper / complete processing
                try {
                    const products = await ProductApi.getProductListAsync(categoreis!, brands!, priceMin!, priceMax!, language!);
                    dispatch({
                        
                        type: ProductListActionType.RECEIVE,
                        payload: { products }
                    });
                } catch (e) {
                    console.error(e);
                }
            },
};

