import React, { useEffect } from "react";
import { IProductDetail } from "../../store/product";
import { useTranslation } from "react-i18next";
import { Cart } from "@bumrungrad/b8d-commerce";
import { getMoney } from "./../../utils/common";
import { useDispatch } from "react-redux";
import { actionCreators, ICart } from "../../store/cart";
interface ProductDetailProps {
  product: IProductDetail;
}
const ProductDetail: React.FunctionComponent<ProductDetailProps> = ({
  product,
}: ProductDetailProps) => {
  const [qty, setQty] = React.useState<number>(1);
  const [isShowToolTip, setIsShowToolTip] = React.useState(false);
  const [isShowShippingCash, setIsShowShippingCash] = React.useState(false);
  const [isShowSelfReceive, setIsShowSelfReceive] = React.useState(false);
  const [isShowWarranty, setIsShowWarranty] = React.useState(false);
  const [isCanShared, setIsCanShared] = React.useState(false);
  const dispatch = useDispatch();
  const handlerQtyChange = (e: any) => {
    let value = e.target.value;
    const re = /^[0-9\b]+$/;
    if ((value > 0 && value <= product.stocks && re.test(value)) || value == "")
      setQty(e.target.value);
  };
  const handlerQtyUpChange = () => {
    let value = qty * 1 + 1;
    if (value <= product.stocks) setQty(value);
  };
  const handlerQtyDownChange = () => {
    let value = qty - 1;
    if (value > 0) setQty(value);
  };
  const { t } = useTranslation();

  return (
    <div className="content product_detail">
      <div className="description">
        <h1>{product.brand}</h1>
        <h2>{product.name}</h2>
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: product.description }}
        ></div>
        <div className="price">
          ฿ {getMoney(product.salePrice)}{" "}
          {product.price == product.salePrice ? null : (
            <span className="discount">
              &nbsp;฿ {getMoney(product.price)} &nbsp;
            </span>
          )}
        </div>
        <div className="status">
          {product.isAvailable ? (
            <div>
              {t("product_detail.status.title")}:{" "}
              {t("product_detail.status.avaiable")} <i className="green"></i>
            </div>
          ) : (
            <div>
              {t("product_detail.status.title")}:{" "}
              {t("product_detail.status.no_avaiable")} <i className="red"></i>
            </div>
          )}
        </div>
        {product.isAvailable ? (
          <div>
            <div className="quantity">
              <span className="left" onClick={handlerQtyDownChange}>
                -
              </span>
              <input
                type="text"
                value={qty}
                onChange={(e) => handlerQtyChange(e)}
              />
              <span className="right" onClick={handlerQtyUpChange}>
                +
              </span>
            </div>
            <Cart
              channelId={product.channelId}
              qty={qty}
              productId={product.productId}
              variantId={product.variantId}
              type="AddToCart"
              text={t("product_detail.add_cart")}
              callBack={(cart) => {
                dispatch(actionCreators.setCart(cart as ICart));
              }}
            />
            <Cart
              channelId={product.channelId}
              qty={qty}
              productId={product.productId}
              variantId={product.variantId}
              type="BuyNow"
              text={t("product_detail.buy_now")}
              callBack={(cart) => {
                dispatch(actionCreators.setCart(cart as ICart));
              }}
            />
          </div>
        ) : null}
        {isCanShared && (
          <div className="share">
            {t("product_detail.share")} <span className="fb"></span>
            <span className="tw"></span>
            <span className="ig"></span>
          </div>
        )}

        <hr />

        <div className="delivery">
          {isShowToolTip && (
            <div className="tooltip">
              <span className="tooltiptext">Tooltip text here.</span>
            </div>
          )}
          <b>{t("product_detail.delivery.title")}</b>
          <div className="option shipping">
            <i></i>
            <span>
              <b>{t("product_detail.delivery.shipping")}</b>
              {t("product_detail.delivery.shipping_claim")}
            </span>
          </div>
          {isShowShippingCash && (
            <div className="option cash">
              <i></i>
              <u></u>
              <span>
                <b>{t("product_detail.delivery.cash")}</b>
                {t("product_detail.delivery.cash_avaiable")}
              </span>
            </div>
          )}
          {isShowSelfReceive && (
            <div className="option recive">
              <i></i>
              <u></u>
              <span>
                <b>{t("product_detail.delivery.revice")}</b>
                {t("product_detail.delivery.revice_claim")}
              </span>
            </div>
          )}
        </div>

        {isShowWarranty && (
          <div className="delivery">
            <div className="tooltip">
              <span className="tooltiptext">
                Tooltip text changing changing hearts can only hearts can
                onlyhere.
              </span>
            </div>
            <b>{t("product_detail.warranty.title")}</b>
            <div className="option return">
              <i></i>
              <u></u>
              <span>
                <b>{t("product_detail.warranty.detail")}</b>
                {t("product_detail.warranty.condition")}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProductDetail;
