import { BaseService } from './base.service';
import { products } from './product-mock';
import type { IProductImage, IProductDetail, IProductInformation, IProduct } from '../store/product';

import type { IProductList } from '../store/productlist';
import { ISelectedItem } from '../store/filter-product';

/**
 * SampleData API abstraction layer communication via Axios (typescript singleton pattern)
 */
class ProductService extends BaseService {
  private static _productService: ProductService;
  private static _controller: string = 'product';

    private constructor(name: string) {
        super(name);
    }

    public static get Instance(): ProductService {
    return this._productService || (this._productService = new this(this._controller));
    }

  public async getProductAsync(id: string, language: string): Promise<IProduct|null> {
    const url = `productdetail?codename=${id}&language=${language ?? 'en'}`;
    try {
      const {data} =  await this.$http.get<IProduct>(url);
      return data;
    }catch{
      return null;
    }
    
    
  }

  public async getProductListAsync(categories: ISelectedItem[], brands: ISelectedItem[], priceMin: number, priceMax: number, language: string): Promise<IProductList[]> {
      const url = `product/getproducts?language=${language ?? 'en'}`;
      const body = { categories: categories, brands: brands, priceMin: priceMin, priceMax: priceMax };
      const { data } = await this.$http.post<IProductList[]>(url, body);
      return data;
  }
}

export const ProductApi = ProductService.Instance;
