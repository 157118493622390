import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../store";
import { actionCreators as actionProduct, IProduct } from "../../store/product";
import ImageSlider from "./ImageSilder";
import ProductDetail from "./ProductDetail";
import ProductInformation from "./ProductInformation";
import { useTranslation } from "react-i18next";

import { useNavigate } from 'react-router-dom';
import { ProductApi } from './../../api/product.service'
import { Oval } from 'react-loader-spinner'

const ProductDetailContainer: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [product, setProduct] = useState<IProduct | null | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const { id: id = "" } = useParams();
    const { i18n } = useTranslation();

    useEffect(() => {
        ProductApi.getProductAsync(id, i18n.resolvedLanguage)
            .then((data) => {
                setProduct(data);
                setLoading(false);
            })
            .catch(() => {
                setProduct(null);
            });
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (product === null) {
            navigate(`/${i18n.resolvedLanguage}/notfound`, { replace: true });
        }
    });

    return (
        <div className="container" style={loading ? { height: "70vh" } : {}}>
            {loading ? <div style={{ margin: "auto", width: "100px", paddingTop: "25vh" }}>
                <Oval height="100" width="100" color='#3e98cc' ariaLabel='loading' /> </div> :
                <div className="row">
                    <div className="col-lg-6 col-12">
                        {product && <ImageSlider
                            imageItems={product.images}
                            maxItems={4}
                            discountPercent={0}
                        />}
                    </div>
                    <div className="col-lg-6 col-12">
                        {product?.detail && <ProductDetail product={product.detail} />}
                    </div>
                    <div className="col-12">
                        {product && <ProductInformation
                            productInformation={product.information}
                        />}
                    </div>
                </div>}
        </div>
    );
};
export default ProductDetailContainer;