
import { FilterProductActionType } from './types';
import type { ReduxAction, AppThunk } from '../';
import type { FilterProductPayload } from './types';

export const actionCreators = {
  resetState: (): ReduxAction<FilterProductPayload> => ({
    type: FilterProductActionType.RESET_STATE,
  }),
  addCategory: (item: {id:string, key?:string, parent: {id:string,typeId:string}, name: string}): AppThunk<FilterProductPayload> => async (dispatch, getState) => {
    let selectedCategories = getState().filterProduct.selectedCategories;
    if(selectedCategories.findIndex(x=>x.id==item.id) === -1){
      selectedCategories.push(item);
    }else{
      selectedCategories = getState().filterProduct.selectedCategories.filter(x=>x.id != item.id);
      selectedCategories = selectedCategories.filter(x=>x.parent.id != item.id);
    }
    // Build http request and success handler in Promise<void> wrapper / complete processing
    try {
      dispatch({
        type: FilterProductActionType.CATEGORY_ADD,
        payload: { selectedCategories },
      });
    } catch (e) {
      console.error(e);
    }
  },
  addBrand: (item: {id:string, key:string, parent: {id:string,typeId:string}, name: string}): AppThunk<FilterProductPayload> => async (dispatch, getState) => {
    let selectedBrands = getState().filterProduct.selectedBrands;
    if(selectedBrands.findIndex(x=>x.id==item.id) === -1){
      selectedBrands.push(item);
    }
    // Build http request and success handler in Promise<void> wrapper / complete processing
    try {
      dispatch({
        type: FilterProductActionType.BRAND_ADD,
        payload: { selectedBrands },
      });
    } catch (e) {
      console.error(e);
    }
  },
  deleteBrand: (item: {id:string, name: string}): AppThunk<FilterProductPayload> => async (dispatch, getState) => {
    let selectedBrands = getState().filterProduct.selectedBrands.filter(x=>x.id != item.id);
    // Build http request and success handler in Promise<void> wrapper / complete processing
    try {
      dispatch({
        type: FilterProductActionType.BRAND_DELETE,
        payload: { selectedBrands },
      });
    } catch (e) {
      console.error(e);
    }
    },
    clearAll: (): AppThunk<FilterProductPayload> => async (dispatch, getState) => {
        let selectedBrands = getState().filterProduct.selectedBrands;
        selectedBrands.splice(0, selectedBrands.length);
        let selectedCategories = getState().filterProduct.selectedCategories;
        selectedCategories.splice(0, selectedCategories.length);
        // Build http request and success handler in Promise<void> wrapper / complete processing
        try {
            dispatch({
                type: FilterProductActionType.BRAND_DELETE,
                payload: { selectedBrands },
            });
            dispatch({
                type: FilterProductActionType.CATEGORY_DELETE,
                payload: { selectedCategories },
            });
        } catch (e) {
            console.error(e);
        }
    },
  setMinPrice: (price?: number | undefined): AppThunk<FilterProductPayload> => async (dispatch) => {
    const minPrice = price;
    // Build http request and success handler in Promise<void> wrapper / complete processing
    try {
      dispatch({
        type: FilterProductActionType.MINPRICE_SET,
        payload: { minPrice },
      });
    } catch (e) {
      console.error(e);
    }
  },
  setMaxPrice: (price?: number | undefined): AppThunk<FilterProductPayload> => async (dispatch) => {
    const maxPrice = price;
    // Build http request and success handler in Promise<void> wrapper / complete processing
    try {
      dispatch({
        type: FilterProductActionType.MAXPRICE_SET,
        payload: { maxPrice },
      });
    } catch (e) {
      console.error(e);
    }
  },
};
