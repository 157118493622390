
export const getMoney = (price: number, minimumFractionDigits?: number, maximumFractionDigits?: number) => {  
  if(price > 0){
    price = price;
    return price.toLocaleString("en-US", {
      minimumFractionDigits: minimumFractionDigits??0,
      maximumFractionDigits: maximumFractionDigits??2,
    });
  }
  return 0;  
}



