/* eslint-disable @typescript-eslint/no-redeclare */

export const ProductCategoryActionType = {
  REQUEST: 'category/fetch',
  RECEIVE: 'category/receive',
  RESET_STATE: 'category/resetState',
  
} as const;

export const BrandCategoryActionType = {
  REQUEST: 'brand/fetch',
  RECEIVE: 'brand/receive',
  RESET_STATE: 'brand/resetState'
} as const;

export type ProductCategoryActionType = typeof ProductCategoryActionType[keyof typeof ProductCategoryActionType];
export type BrandCategoryActionType = typeof BrandCategoryActionType[keyof typeof BrandCategoryActionType];





export type ICategory = Readonly<{
  id: string;
  key: string;
  name: string;
  slug: {};
  description: {};
  custom: {};
  metaTitle: {};
  assets: IAsset[];
  child: ICategory[];
  parent: IReference;
}>;

export type IReference = Readonly<{
  id: string;
  typeId: string;
}>;

export type IAsset = Readonly<{
  id: string;
  typeId: string;
}>;

export type ICategoryState = Readonly<{
  productCategories: ICategory[];
  brandCategories: ICategory[];
  productIsLoading: boolean,
  brandIsLoading: boolean,
}>;

export type CategoryStatePayload = Partial<ICategoryState>;