import { IProductImage, IProductDetail, IProduct, ProductActionType } from './types';

import type { ReduxAction } from '..';
import type { ProductPayload, IProductState } from './types';
import { channel } from 'diagnostics_channel';

const initialState: IProductState = {
  product: {
    images: [],
    detail: {
      brand : '',
      description: '',
      id: '',
      isAvailable: false,
      name: '',
      price: 0,
      salePrice: 0,
      shippingPrice: 0,
      stocks: 0,
      productId: '',
      variantId: '',
      channelId: ''
    },
    information: {
      detail: '',
      specification: '',
      warning: ''
    }
  }
};

export const reducer = (
  state: IProductState = initialState,
  action: ReduxAction<ProductPayload>
): IProductState => {
  switch (action.type) {
    case ProductActionType.GET_PRODUCT: {
      const { product = {} as IProduct  } = action.payload as ProductPayload;

      return {
        ...state,
        product
      };
    }
    default:
      return state;
  }
};
