import * as React from 'react';
import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import IconWhatApp from './../assets/images/ic_whatsapp.png'
import IconFaceBook from './../assets/images/ic_fbchat.png'
import IconTel from './../assets/images/ic_tel.png'
import IconEmail from './../assets/images/ic_email.png'
import IconPharmacy from './../assets/images/iconicpharmacy_b.png'
import IconBumrungrad from './../assets/images/bumrungrad.png'
import { useTranslation } from 'react-i18next';


const Footer: React.FunctionComponent = () => {
    const { i18n } = useTranslation();
    const [activeTab, setActiveTab] = React.useState("footer_tab1");
    return (
        <React.Fragment>

            <nav id="iconic_footer">
                <div className="tab_content">
                    <div className="tab_content_fix">
                        <div className={`tab footer_tab1 ${activeTab=="footer_tab1"?"selected":""}`} onClick={()=>setActiveTab("footer_tab1")}><img src={IconPharmacy}/></div>
                        <div className={`tab footer_tab2 ${activeTab=="footer_tab2"?"selected":""}`} onClick={()=>setActiveTab("footer_tab2")}>SITE MAP</div>
                        <div className="share">
                            <b>FOLLOW US</b>
                            <a className="fb" href="https://m.me/iconicpharmacy/"></a>
                            <a className="line" href="https://lin.ee/5LB0yGm"></a>
                        </div>
                    </div>
                </div>
                <div className={`default iconic_footer_content text-center ${activeTab=="footer_tab1"?"d-block":"d-none"}`}>
                    <div className="enews d-none">
                        <b>ADD MORE HEALTH TO YOUR EMAIL</b>
                        <input className="email" placeholder="Your Email"/>
                        <span>OK</span>
                    </div>
                    <div className="social" style={{"borderLeft":"none"}}>
                        <b>STAY IN TOUCH WITH ICONIC</b>
                        <span>Pharmacy Store by Pharmacists Open today until 8:00 PM</span>
                        <div><a href={`tel:${process.env.REACT_APP_API_WHATSAPP}`}><img src={IconWhatApp}/><i>WhatsApp</i></a></div>
                        <div><a href={process.env.REACT_APP_API_FACEBOOK_MESSENGER}><img src={IconFaceBook}/><i>Facebook<br/>Messenger</i></a></div>
                        <div><a href={`tel:${process.env.REACT_APP_API_TEL}`}><img src={IconTel}/><i>(02) 011 4530</i></a></div>
                        <div><a href={`mailto:${process.env.REACT_APP_API_EMAIL_SUPPORT}`}><img src={IconEmail}/><i>Send<br className="br_480"/> an e-mail</i></a></div>
                    </div>
                </div>
                <div className={`sitemap iconic_footer_content ${activeTab=="footer_tab2"?"d-block":"d-none"}`}>
                    <div className="column_mobile">
                        <div className="column md">
                            <a className="m1 menu_footer no_point">HEALTH &amp; ADVICE<i></i></a>
                            <span>
                                <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=hot-issues`}>Hot Issues</a>
                                <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=health`}>Health Articles</a>
                                <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=weight-control-exercise`}>Weight Control &amp; Exercise</a>
                                <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=birth-control`}>Birth Control</a>
                                <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=beauty-health-skincare`}>Beauty Health<br className="show_pc"/>And Skincare</a>
                                <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/article.html?tab=vitamin`}>Vitamins &amp; Supplements<br/>For Each Age</a>
                            </span>
                        </div>
                        <div className="column lg">
                            <a className="m2 menu_footer" href={`/${i18n.resolvedLanguage}/products.html`}>OUR PRODUCTS</a>
                            
                        </div>
                    </div>
                    <div className="column_mobile">
                        <div className="column lg">
                            <a className="m3 menu_footer" href={process.env.REACT_APP_API_LINE_ADDFRIEND}>PHARMACY SERVICE</a>
                           
                        </div>
                        <div className="column">
                            <a className="m4 menu_footer" href={process.env.REACT_APP_API_LINE_ADDFRIEND}>MEMBER SERVICE</a>
                            
                        </div>
                        <div className="column">
                            <a className="m5 menu_footer" href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/contactus.html`}>CONTACT US<i></i></a>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className="link">
                    <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/terms.html`}>TERMS <span>&amp; CONDITIONS</span></a>
                    <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/privacy.html`}>PRIVACY <span>POLICY</span></a>
                    <a href={`${process.env.REACT_APP_API_CONTENT_ENDPOINT}/${i18n.resolvedLanguage}/cookies.html`}>COOKIES <span>POLICY</span></a>
                </div>
                <div className="copy">
                    <a href={`https://www.bumrungrad.com/${i18n.resolvedLanguage}`} target="_blank"><img src={IconBumrungrad}/></a><b>© 2021 Iconic Pharmacy, All Rights reserved.</b>
                </div>
            </nav>

        </React.Fragment>);
    


};

export default Footer;
