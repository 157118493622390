import { Fragment, FunctionComponent } from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import Footer from "./Footer";

const Layout: FunctionComponent = ({ children }) => (
  <Fragment>
    <NavMenu />
    {children}
    <Footer />
  </Fragment>
);
export default Layout;
