import { FunctionComponent, useEffect } from 'react';


const Home: FunctionComponent = () => {
  useEffect(()=>{
    window.location.href = `${process.env.REACT_APP_API_CONTENT_ENDPOINT}`
  });
  return (
    <></>
  )
};

export default Home;
