import { CartActionType,  ICart } from './types';

import type { ReduxAction } from '..';
import type { CartPayload, ICartState } from './types';

const initialState: ICartState = {
  totalItems: undefined,
  version: undefined
};

export const reducer = (
  state: ICartState = initialState,
  action: ReduxAction<CartPayload>
): ICartState => {
  switch (action.type) {
    case CartActionType.SET_CART: {
      const { version ,totalItems } = action.payload as CartPayload;
      return {
        ...state,
        totalItems,
        version
      };
    }
    default:
      return state;
  }
};
