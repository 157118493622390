import { FilterProductActionType, ISelectedItem } from './types';

import type { ReduxAction } from '../';
import type { FilterProductPayload, IFilterProductState } from './types';

const initialState: IFilterProductState = {
  selectedCategories: [],
  isLoading: false,
  selectedBrands: [],
  minPrice: undefined,
  maxPrice: undefined
};

export const reducer = (
  state: IFilterProductState = initialState,
  action: ReduxAction<FilterProductPayload>
): IFilterProductState => {
  switch (action.type) {
    case FilterProductActionType.CATEGORY_ADD: {
      const { selectedCategories = [] as ISelectedItem[] } = action.payload as FilterProductPayload;

      return {
        ...state,
        selectedCategories
      };
    }
    case FilterProductActionType.CATEGORY_DELETE: {
      const { selectedCategories = [] as ISelectedItem[] } = action.payload as FilterProductPayload;
      return {
        ...state,
        selectedCategories
      };
    }
    case FilterProductActionType.BRAND_ADD: {
      const { selectedBrands = [] as ISelectedItem[] } = action.payload as FilterProductPayload;

      return {
        ...state,
        selectedBrands
      };
    }
    case FilterProductActionType.BRAND_DELETE: {
      const { selectedBrands = [] as ISelectedItem[] } = action.payload as FilterProductPayload;
      return {
        ...state,
        selectedBrands
      };
    }
    case FilterProductActionType.MINPRICE_SET: {
      const { minPrice } = action.payload as FilterProductPayload;
      return {
        ...state,
        minPrice
      };
    }
    case FilterProductActionType.MAXPRICE_SET: {
      const { maxPrice } = action.payload as FilterProductPayload;
      return {
        ...state,
        maxPrice
      };
    }
    default:
      return state;
  }
};
