import * as React from 'react';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import {isMobile} from 'react-device-detect';
import ProductFilter from './ProductFilter';
import { actionCreators } from '../../store/categories';
import { actionCreators as filterActionCreators } from '../../store/filter-product';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ISelectedItem } from '../../store/filter-product';
import { connect, ConnectedProps } from 'react-redux';
import ProductList from '../ProductList';
import { IProductList } from '../../store/productlist';
import { useTranslation } from 'react-i18next';


const SampleProductList: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { i18n,t } = useTranslation();
  const [isShowSort, setIsShowSort] = React.useState(false);
  const filterProduct = useSelector<RootState,{ isLoading: boolean;
      selectedCategories: ISelectedItem[];
      selectedBrands: ISelectedItem[];
      minPrice?: number;
      maxPrice?: number;
  } >((state) => state.filterProduct);
  useEffect(() => {
    dispatch(actionCreators.requestBrandCategories(i18n.resolvedLanguage));
    dispatch(actionCreators.requestProductCategories(i18n.resolvedLanguage));
  }, [dispatch, i18n]);
 
  const selectedFilterHandlerClick = (item: {id:string, key?:string, parent: {id:string,typeId:string}, name: string}) => {

    dispatch(filterActionCreators.addCategory(item));
    
  } 
  const selectedFilterBrandHandlerClick = (item: {id:string, key?:string, parent: {id:string,typeId:string}, name: string}) => {

    dispatch(filterActionCreators.deleteBrand(item));
    
  } 
  const handlerPriceRangeClick = () => {

      dispatch(filterActionCreators.setMinPrice(undefined));
      dispatch(filterActionCreators.setMaxPrice(undefined));

    }

    const products = useSelector<RootState, IProductList[]>((state) => state.productList.products!);

  return (
    <React.Fragment>
      <div className='content'>

         
          <div className='main'>
            <ProductFilter/>
           {(filterProduct.selectedCategories?.length > 0 || filterProduct.selectedBrands?.length > 0 || (!!filterProduct.minPrice || !!filterProduct.maxPrice)) &&  <div className="filter_by show_pc">
              {<b className='filter_label'>{t('product_filter.filter_by')}:</b>}
              {
                  filterProduct.selectedCategories && filterProduct.selectedCategories.map((item)=> (
                      <span key={item.id} className='selected-filter selected-category'  onClick={()=>selectedFilterHandlerClick(item)}>{item.name}</span>
                  ))
              }   
              {
                  filterProduct.selectedBrands && filterProduct.selectedBrands.map((item)=> (
                      <span key={item.id} className='selected-filter selected-brand'   onClick={()=>selectedFilterBrandHandlerClick(item)}>{item.name}</span>
                  ))
              }
              {(!!filterProduct.minPrice || !!filterProduct.maxPrice) && <span className='selected-filter' onClick={()=>handlerPriceRangeClick()}> Price Range : {filterProduct.minPrice?Intl.NumberFormat().format(filterProduct.minPrice):'1'} - {filterProduct.maxPrice?Intl.NumberFormat().format(filterProduct.maxPrice):'Max'}</span>}    
            </div>}
           {isShowSort && <div className="sort_by">
              <span className="text">{t('product_filter.sort_by')}:</span>
              <select name="sort" id="sort">
                          <option value="best">{t('product_filter.sort.best_sellers')}</option>
                          <option value="hot">{t('product_filter.sort.hot_items')}</option>
                          <option value="price_high">{t('product_filter.sort.price_high_to_low')}</option>
                          <option value="price_low">{t('product_filter.sort.price_low_to_high')}</option>
                          <option value="discount_high">{t('product_filter.sort.discount_low_to_high')}</option>
                          <option value="discount_low">{t('product_filter.sort.discount_high_to_low')}</option>
              </select>
            </div>}
                  <div className="item show_pc">
                      <b>{products.length}</b> {t('product_filter.items')}
                  </div>
                  <div id="product" className="relate products content">
                      <ProductList/>
                  </div>
          </div>
      </div>
    </React.Fragment>
  );
}

export default (SampleProductList);
