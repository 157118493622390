import React from 'react';
import { IProductInformation } from '../../store/product';
import { useTranslation } from 'react-i18next';
interface ProductInformationProps{
    productInformation: IProductInformation
}
const ProductInformation: React.FunctionComponent<ProductInformationProps> = ( { productInformation }: ProductInformationProps) => {
    const [toggle, setToggle] = React.useState(0);
    const handlerToggleClick = (id: number) => {
        if(id == toggle)
            id = 0
        setToggle(id)
    }
    const { t } = useTranslation();
    return (
    <div className='content product_detail'>
        <div className='tab_content'>
            <div className='tab_head'>{t('product_detail.information')}</div>
            <div className='tab detail selected'>
               <div className='tab_header'> <i></i><b>{t('product_detail.detail')}</b></div>
               <div className='tab_body' dangerouslySetInnerHTML={{ __html: productInformation.detail}}></div>
            </div>
            <div className={ 'tab specification ' + (toggle == 1 ? 'selected' : '')} >
                <div className='tab_header' onClick={() => handlerToggleClick(1)}><i></i><b>{t('product_detail.specification')}</b></div>
                <div className='tab_body' dangerouslySetInnerHTML={{ __html: productInformation.specification }}></div>
            </div>
            <div className={ 'tab warning ' + (toggle == 2 ? 'selected' : '')}>
                <div className='tab_header' onClick={() => handlerToggleClick(2)}><i></i><b>{t('product_detail.warning')}</b></div>
                <div className='tab_body' dangerouslySetInnerHTML={{ __html: productInformation.warning}}></div>
            </div>
        </div>
    </div>
    );
}
export default ProductInformation;
  