import { BrandCategoryActionType, ProductCategoryActionType } from './types';

import type { ReduxAction } from '../';
import type { CategoryStatePayload, ICategoryState } from './types';


const initialState: ICategoryState = {
  brandCategories: [],
  productCategories: [],
  brandIsLoading: false,
  productIsLoading: false
};

export const reducer = (
  state: ICategoryState = initialState,
  action: ReduxAction<CategoryStatePayload>
): ICategoryState => {
  switch (action.type) {
    case ProductCategoryActionType.REQUEST: {
      return {
        ...state,
        productCategories: initialState.productCategories,
        productIsLoading: true
      };
    }
    case ProductCategoryActionType.RECEIVE: {
      const {
        productCategories = initialState.productCategories,
      } = action.payload as CategoryStatePayload;

      // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
      return {
        ...state,
        productCategories,
        productIsLoading: false
      };

    }

    case BrandCategoryActionType.REQUEST: {
      return {
        ...state,
        brandCategories: initialState.brandCategories,
        brandIsLoading: true
      };
    }
    case BrandCategoryActionType.RECEIVE: {
      const {
        brandCategories = initialState.brandCategories
      } = action.payload as CategoryStatePayload;

      // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
      return {
        ...state,
        brandCategories,
        brandIsLoading: false
      };

    }
    default:
      return state;
  }
};
