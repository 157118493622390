
import { BrandCategoryActionType, ProductCategoryActionType } from './types';

import type { ReduxAction, AppThunk } from '../';
import type { CategoryStatePayload } from './types';
import { CategoryApi } from '../../api/category.service';

export const actionCreators = {
  productCategoryResetState: (): ReduxAction<CategoryStatePayload> => ({
    type: ProductCategoryActionType.RESET_STATE,
  }),
  requestProductCategories: (language?: string): AppThunk<CategoryStatePayload> => async (dispatch) => {

      let productCategories;
      // Dispatch request to intialize loading phase
      dispatch({
        payload: {  },
        type: ProductCategoryActionType.REQUEST,
      });

      // Build http request and success handler in Promise<void> wrapper / complete processing
      try {
        productCategories = await CategoryApi.getProductCategoryAsync(language!);
        dispatch({
          type: ProductCategoryActionType.RECEIVE,
          payload: { productCategories },
        });
      } catch (e) {
        dispatch({
          type: ProductCategoryActionType.RECEIVE,
          payload: { productCategories },
        });
      }
  },
  requestBrandCategories: (language?: string): AppThunk<CategoryStatePayload> => async (dispatch) => {
    let brandCategories;
    // Dispatch request to intialize loading phase
    dispatch({
      payload: {  },
      type: BrandCategoryActionType.REQUEST,
    });

    // Build http request and success handler in Promise<void> wrapper / complete processing
    try {

      brandCategories = await CategoryApi.getBrandCategoryAsync(language!);
      dispatch({
        type: BrandCategoryActionType.RECEIVE,
        payload: { brandCategories },
      });
    } catch (e) {

      dispatch({
        type: BrandCategoryActionType.RECEIVE,
        payload: { brandCategories  },
      });
    }
  },
};
