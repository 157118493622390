import { ICategory } from "../categories/types";
import { ISelectedItem } from "../filter-product";

export const ProductListActionType = {
    REQUEST: 'product/fetch',
    RECEIVE: 'product/receive',
    RESET_STATE: 'product/resetState',
} as const;

export type ProductListActionType = typeof ProductListActionType[keyof typeof ProductListActionType];

export type IProductList = Readonly<{
    skuCode?: string;
    productId?: string;
    variantId?: string;
    productName?: string;
    description?: string;
    price?: number;
    discountPrice?: number;
    discountPercent?: number;
    productThumbnail?: string;
    productKey?: string;
    channelId?: string;
    codeName?: string;
}>;

export type IProductListState = Readonly<{
    isLoading?: boolean;
    categories?: ISelectedItem[],
    brands?: ISelectedItem[],
    priceMin?: number,
    priceMax?: number,
    products?: IProductList[],
}>;

export type ProductListPayload = Partial<IProductListState>;