import { BaseService } from './base.service';

import type { ICategory } from '../store/categories';

/**
 * SampleData API abstraction layer communication via Axios (typescript singleton pattern)
 */
class CategoryService extends BaseService {
  private static _filterProductService: CategoryService;
  private static _controller: string = 'masterdata';

  private constructor(name: string) {
    super(name);
  }

  public static get Instance(): CategoryService {
    return this._filterProductService || (this._filterProductService = new this(this._controller));
  }

  public async getProductCategoryAsync(language: string): Promise<ICategory[]> {
    const url = `masterdata/productcategory?language=${language ?? 'en'}`;
    const { data } = await this.$http.get<ICategory[]>(url);

    return data;
  }

  public async getBrandCategoryAsync(language: string): Promise<ICategory[]> {
    const url = `masterdata/brandcategory?language=${language ?? 'en'}`;
    const { data } = await this.$http.get<ICategory[]>(url);

    return data;
  }
}

export const CategoryApi = CategoryService.Instance;
