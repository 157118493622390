/* eslint-disable @typescript-eslint/no-redeclare */

export const CartActionType = {
    SET_CART: 'cart/setCart',
    GET_CART: 'cart/getCart',
  } as const;
  
export type ICart = Readonly<{
    version?: number;
    totalItems?: number;
}>;

export type ICartState = Readonly<{
    version?: number;
    totalItems?: number;
}>;
  
export type CartPayload = Partial<ICartState>;
  
  