import { ProductListActionType } from './types';

import type { ReduxAction } from '../';
import type { ProductListPayload, IProductListState } from './types';

const initialState: IProductListState = {
    products: [],
    isLoading: false
};

export const reducer = (
    state: IProductListState = initialState,
    action: ReduxAction<ProductListPayload>
): IProductListState => {
    switch (action.type) {
        case ProductListActionType.REQUEST:
            {
                return {
                    ...state,
                    isLoading: true
                };
            }
        case ProductListActionType.RECEIVE: {
            const {
                brands = [], categories = [], priceMax = 0, priceMin = 0, products = []
            } = action.payload as ProductListPayload;

            // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
            return {
                brands, categories, isLoading: false, priceMax, priceMin, products
            };

        }
        default:
            return state;
    }
};
