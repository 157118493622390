import { memo } from 'react';
import styled, { keyframes } from 'styled-components';

type SpinnerProps = Readonly<{
  isLoading: boolean;
}>;



const IconicSpinner = memo<SpinnerProps>(({ isLoading }) => (
    <div className="scroller-status" style={{clear: 'both', textAlign: 'center', display: isLoading?'block':'none'}}>
        <div className="page_load "></div>
        <p className="infinite-scroll-error" style={{display: 'block'}}>Loading</p>
    </div>
));



export default IconicSpinner;